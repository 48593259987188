 <template>
  <div 
  class="modal fade modal-mask"
    :style='getModalStyle()'
    id="fwEditModal"
    tabindex="1"
    :z-index="zIndex"
    role="dialog"
    aria-labelledby="fwEditModal"
    aria-hidden="true"
  >
   <div class="modal-wrapper">
     <div class="mymodal-container shadow" :style="getAppStyle()+getContentStyle()+'border: 2pt inset grey;'">
		
         <div class='appHead'>
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'> </div>
             
			 <button id='rotate' type="button" class="navBN iButton" @click="tabLayoutIDX++" title='switch navigation'>
				<mdicon size="24" name="rotate-left"/>
			 </button>	
			 <button id='dock' type="button" class="navBN iButton" @click="style=='classic'?style='navRight':style='classic'" title='switch navigation'>
			 <mdicon v-if="style=='classic'" size="24" name="dock-bottom"/>
			 <mdicon v-else size="24" name="dock-right"/>
			 </button>
			 
              <button id='fully' type="button" class="navBN iButton" @click='maximize()' aria-label="Maximize" title='maximize/minimize popup'>
				<mdicon v-if="width != 100" size="24" name="fullscreen"/>
				<mdicon v-else size="24" name="fullscreen-exit"/>
            </button>
			
			<WindowCloseBN :style="'padding-top: 5pt;'" :withDarkMode="false"  @click='closeUntilPosition(-1)'/>
			
            </div>
       <div class="modal-header" :style="getAppStyle()">
          <slot name="header">
            <div class='appHeadLine' :style="getAppStyle()+'display: block;'">
             
            	<div class='appLeft' :style="getAppStyle()+'position: absolute; left: 0; bottom: 0;'">
            	  
            	  <div style='float: left;'>
                  <a v-for="(p,idx) in path" :key="idx" @click=closeUntilPosition(idx) class='breadcrumbItem'>{{p.name}}</a>
            	  <div class='breadcrumbItem breadcrumbItemCurrent' >
            	  {{data.visualDisplay}}
				  </div>
            	  
                </div>
            	</div>
                <div class='appRight' :style="getAppStyle()+'position: absolute; right: 5pt; bottom: 0;'">
                  {{(metadata && metadata.menuTitle)?metadata.menuTitle:metadata.name}}
            	  <span v-if="numberOfRows" class='appCounter'>{{visualIndex+1}}/{{numberOfRows}}</span>
                </div>
                
                </div>
            
           </slot>
        </div>
		<div :style="getAppStyle()+getMainContentStyle()+';display: flex; float:top;  position: relative;'">
			  
        <div class="modal-body" 
		     :style="getAppStyle()+(style=='classic'?'width: 100% !important;': 'width: 85% !important;')+'height: 100% !important; display: block; float: left; position: relative'"> 	
          <slot name="body">
            <div class='mainContent' :style="getAppStyle()">
           
	            <tabs animation="slide" :only-fade="false" size="small" :style="getAppStyle()+'height: 92%; font-size: 9pt !important;'" 
				        :layout='tabLayout[tabLayoutIDX%tabLayout.length]' :selectedIndex="selectedTab"  
	            		@tab-selected='tabChange'>
		            <tab-pane v-for='(tab,tabIdx) in checkFolder(folderDefinition)' 
		                      :key="'t_'+tabIdx" :label="tab.display" :style="getAppStyle()"
		                      :selected="selectFirstTab(tab, tabIdx)">
		                      
		            <table v-if="selectedTab===tabIdx" width='100%' class='transition editTable'>
		            	<tr v-for='(row,rowIdx) in tab.rows' :key="'tr_'+tabIdx+'_'+rowIdx">
		            		<td v-for='(col,colIdx) in row' 
		            		    :key="'td_'+tabIdx+'_'+rowIdx+'_'+colIdx" 
		            		    :colspan='col.span' style='margin: 0pt;' 
		            		    :rowspan="isShare(col.data)?5:1"
		            		    :width='(100/tab.cells)+"%"'
		            		>
		            		    
		            			<div 
		            			   :id="'c_'+tabIdx+'_'+rowIdx+'_'+colIdx"              
				                   @contextmenu="menuRClicked($event, tab, tabIdx, col)"
				                   class='editorElement' :style="getStyleX(tab, col)">
				               
				                  <span v-if="visible(col.data) && !isLink(col.data)" 
				                       @click="showHistory($event, col.data)"
				                       :class='getLabelClass(tab, col)'  :style="getLabelStyleX(tab, col)" >
									  
				                      <span v-if="col.data.tooltip" v-tooltip="toolTip(tab, col)">{{ getDisplay(col) }}</span>
				                      <span v-else >{{ getDisplay(col)}}</span>
				                      <!--:title="JSON.stringify(col.data)"-->
				                  </span> 
				                  <span class='elementField' :style="getFieldStyleX(tab,col)">
				                 	 <!-- {{col.name+">"+col.data.type}}-->
		
				                 	  <hr v-if="(col.name === 'separator')" class='separator'/>	
				                 	  <div v-else-if="col.name === 'label'" style="display: block; font-size: 8pt; padding-bottom: 5pt;">
										<div style="">{{col.label}}</div>   
				                 	  	
				                 	  </div>     
				                 	  <InventoryFilterEditor  v-else-if="isInventoryFilter(col.data)"
				                 	  	v-model="data[ col.name]"
				                 	  	:columnName="col.name"
				                 	  	:disabled="!col.data.editable" 
				                 	  	:parentRecord="data"
				                 	  	@open="openInvEditor"
				                 	  >
				                 	  </InventoryFilterEditor>
				                 	  <span v-else-if="isPhaseDef(col.data)" @click="openPhaseEditor(data, col.name, entityMap.solver.data)">
				                 	  <span v-if="data[ col.name] && data[ col.name].phases">
				                       <span style='border: 1px dotted #ccc; padding:2pt; font-size: 8pt;margin-right: 4pt; border-radius: 4pt; ' v-for="(phase, pIdx) in data[ col.name].phases" :key="'pHI'+pIdx">
				                       {{phase.name}}
				                       </span> 
				                      
				                      </span> 
				                      <span v-else>
				                      no phases
				                      </span>
				                 	  </span>
				                 	  <HouseNumbers v-else-if="isHousenumbers(col.data)" 
					                            :mark=mark[col.name]
					                            :name="col.name"
												:copyId="data.id"
					                            @changed="useCopyFrom(col.name)"
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
									  <InputJSON v-else-if="isJSON(col.data)" 
					                            :mark=mark[col.name]
					                            :name="col.name"
					                            @changed="useCopyFrom(col.name)"
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
									 <InputURL v-else-if="isURL(col.data)" 
					                            :mark=mark[col.name]
					                            :name="col.name"
					                            @changed="useCopyFrom(col.name)"
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
					                  <InputDynDate v-else-if="isDynamicDate(col.data)" 
					                            :mark=mark[col.name]
					                            @changed="useCopyFrom(col.name)"
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
									  <div v-else-if="isStringArr(col.data)">
										
										<InputTextARR 
					                            :mark=mark[col.name]
					                            @changed="useCopyFrom(col.name)"
					                            
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
									  </div>
									  
									  <InputTextISODur v-else-if="isISODur(col.data)" 
  					                            :mark=mark[col.name]
												:dataId=data.id
  					                            @enter="update()" :disabled="!col.data.editable" 
  					                            v-model="data[ col.name]"/>
									  
					                  <InputText v-else-if="isString(col.data)" 
					                            :mark=mark[col.name]
					                            @changed="useCopyFrom(col.name)"
					                            
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
									  
					                   <div v-else-if="isShare(col.data) && data[ col.name]" class="settingsBlock" style="width: 100% !important;">
					                   <Shares  v-model="data[ col.name].shares" 
					                            :shareTypes="data[ col.name].shareTypes?data[ col.name].shareTypes:[]" 
					                            :count="shFilterCount"
					                            :dataId=data.id
					                            @openInvEditor="openInvEditorShare"
					                            @removeShare="removeShare" @copyShare="copyShare">
					                   </Shares>
					                   </div>
									   <div v-else-if="isWeekShareLimits(col.data) && data[ col.name]" >
									    <div class="settingsBlock" >
										<WeekAndTimeLimits :placementId="data.id" 
									                       v-model="data.weekShareLimits" 
														   fromEditor="true"
											               :timeFrom="6*3600" :timeTo="24*3600"
						   				                   :weeklies="data.weekLimits" @weeklies="setWeeklies" 
						   								   :sharesInp="data.shares" :budget="data.budget"/>
										</div>
									   </div>
					                   <div v-else-if="isWeekLimits(col.data) && data[ col.name]" class="settingsBlock" style="width: 100% !important;">
					                   
					                   <WeekLimits  v-model="data[ col.name]" 
					                            :placementId="data.id"
					                            :fromEditor="true" 
					                            :budget="data.budget"
					                          >
					                   </WeekLimits>
					                   </div>
			                            <InputColumnHeader v-else-if="isColumnHeader(col.data)" 
				                            :recordId=data.id
				                            :metadata=metadata
				                            :mark=mark[col.name]
				                            :entityMap=entityMap
				                            :column=get(col.name)
					                        :selectedTab=selectedTab
					                        :tabNo=tabIdx
					                        :definition="findColumn(col.name)"
					                        :useCache=false
				                            @enter="update()" :disabled="!col.data.editable" 
				                            v-model="data[ col.name]"/>
										<InputEnumList v-else-if="isEnumList(col.data)" 
				                            :recordId=data.id
				                            :metadata=metadata
											:explanation="data[ 'e'+col.name+'explained']"
				                            :mark=mark[col.name]
				                            :entityMap=entityMap
				                            :column=get(col.name)
					                        :selectedTab=selectedTab
					                        :tabNo=tabIdx
					                        :definition="findColumn(col.name)"
					                        :useCache=false
				                            @enter="update()" :disabled="!col.data.editable" 
				                            v-model="data[ col.name]"/>	
					                    <InputColour :ref='inpColor' v-else-if="isColour(col.data)" 
					                            :mark=mark[col.name]
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
					                    <InputWeekdays v-else-if="isWeekday(col.data)"  
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]" :time="new Date().getTime()"/>
					                    <div v-else-if="isTime(col.data)"> 
					                   
					                    <InputTime  
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]" :time="new Date().getTime()"/>  
					                            </div> 
					                    <InputInt v-else-if="isInt(col.data)" 
					                            @enter="update()" :disabled="!col.data.editable"
					                            @changed="useCopyFrom(col.name)"  
					                            v-model.number="data[ col.name]"/>     
					                    <InputMoney v-else-if="isMoney(col.data)" 
					                            @enter="update()" :disabled="!col.data.editable"  
					                            :nulls="!col.data.mandatory"
					                            :currency=data.currencyId
					                            :dataMap=entityMap.Currency
					                            v-model="data[col.name]"
					                            >
					                    </InputMoney> 
					                    <div v-else-if="isDouble(col.data)">         
					                    <InputDouble  
					                            @enter="update()" :disabled="!col.data.editable"  
					                            :nulls="!col.data.mandatory"
					                            v-model="data[ col.name]"/> 
					                    </div>    
					                    <InputDatePick v-else-if="isDate(col.data)" :auto="false"
					                            @enter="update()" :disabled="!col.data.editable"  
					                            v-model="data[ col.name]"/>    
					                    <div v-else-if="isDatetime(col.data)"> 
					                   
					                    <InputDateTime  
					                            @enter="update()" :disabled="!col.data.editable"  
					                            v-model="data[ col.name]"/>    
					                            </div> 
					                    <div v-else-if="isBool(col.data)">
					                    <!--{{data[ col.name]}}-->
					                    <app-switch  :disabled="!col.data.editable" 
					                            v-model="data[col.name]" />     
					                    </div>
					                    <InputSecret v-else-if="isSecret(col.data)" 
					                            @enter="update()" :disabled="!col.data.editable"
					                            v-model="data[ col.name]"/>
					                    <GSelectModel v-else-if="isModel(col.data)" 
					       						:visible="selectedTab == tabIdx"
					                        	:disabled="!col.data.editable"
					                            :index=data.id :time="new Date().getTime()"
					                            v-model="data[ col.name]"/>
					               
					                    <GImage v-else-if="isImg(col.data)" :data="getImgData(data[col.name])" 
					                        @clear="data[col.name]=null"
					                    	:entity='metadata.name' :column="col.name" :id="data.id" @reload="readDataRecord()"
					                    	 />
					                    <GFile v-else-if="isFile(col.data)" :data="data[col.name]" 
					                    	:entity='metadata.name' :column="col.name" :id="data.id" @reload="readDataRecord()"
					                    	 />
					                    <div  v-else-if="isLink(col.data)" >
					                    	<b>Link:</b> {{col.name}}
					                    <GLink   
					                        :visible="selectedTab == tabIdx" :s=selectedTab :t=tabIdx :counter=updateCount
					                        @open=openLink 
					                        :data=data :id=data.id :metadata=metadata :definition="col.data" />
					                    </div>
										<InputOptionList v-else-if="isOptionList(col.data)" 
					                        :visible="selectedTab == tabIdx"
					                        :mark=mark[col.name]
					                        :disabled="!col.data.editable"
					                        :useCache="true"
					                        :entityMap="entityMap"
					                        :parentMeta="getParentMeta()"
					    					:parentId="parentId"
					                         v-model="data[ col.name]"
					                         @open=openLink
					                         @input="onSelect(col.name)"
					                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="col.data" />
										<GSelectM v-else-if="isMSelect(col.data)" 
					                        :visible="selectedTab == tabIdx"
					                        :mark=mark[col.name]
					                        :disabled="!col.data.editable"
					                        :useCache="true"
					                        :entityMap="entityMap"
					                        :parentMeta="getParentMeta()"
					    					:parentId="parentId"
					                         v-model="data[ col.name]"
					                         @open=openLink
					                         @input="onSelect(col.name)"
					                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="col.data" />
					                   <div  v-else-if="isSelect(col.data)" >
					                    	
					                    <GSelect 
					                        :visible="selectedTab == tabIdx"
					                        :column=get(col.name)
					                        :mark=mark[col.name]
					                        :useCache="true"
					                        :disabled="!col.data.editable"
					                        :entityMap=entityMap
					                        :parentMeta="getParentMeta()"
					    					:parentId=parentId
					                         v-model="data[ col.name]"
					                         @open=openEntity
					                         @input="onSelect(col.name)"
					                        :data=data :id=recordId :time="new Date().getTime()" :metadata=metadata :definition="col.data" />
					                    </div>
					                    <div  v-else-if="isTz(col.data)" >
					                        
						                    <InputTimezone 
						                        :visible="selectedTab == tabIdx"
						                        :disabled="!col.data.editable"
						                        :entityMap="entityMap"
						                         v-model="data[ col.name]"
						                         @open=openLink
						                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="col.data" />
					                    </div>
					                    <GSelectM v-else-if="mightBeDynSelect(col.data) && isDynMSelect(col.data)" 
					                        :visible="selectedTab == tabIdx"
					                        :mark=mark[col.name]
					                        :disabled="!col.data.editable"
					                        :useCache="false" 
					                        :entityMap="entityMap"
					                        :parentMeta="getParentMeta()"
					    					:parentId="parentId"
					                         v-model="data[ col.name]"
					                         @open=openLink
					                         @input="onSelect(col.name)"
					                        :data=data :id=data.id :time=colIdx :metadata=metadata :definition="col.data" />
					                    <div v-else-if="mightBeDynSelect(col.data)">
					                        
						                    <GSelect  
						                        :visible=true
						                        :selectedTab=selectedTab
						                        :tabNo=tabIdx
						                        :mark=mark[col.name]
						                        :useCache=false
						                        :disabled="!col.data.editable"
						                        :entityMap=entityMap
						                        :parentMeta="getParentMeta()"
						    					:parentId=parentId
						                         v-model="data[ col.name]"
						                         @open=openLink
						                         @input="onSelect(col.name)"
						                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="col.data" />
					                    </div>
					                    <InputText v-else-if="mightBeDynSelect(col.data)" 
					                            :mark=mark[col.name]
					                            @changed="useCopyFrom(col.name)"
					                            @enter="update()" :disabled="!col.data.editable" 
					                            v-model="data[ col.name]"/>
					                    <vSelect v-else-if="mightBeDynSelect(col.data) && isDynDate(col.data)" 
					                        :options="[{id: 'now()',label:'Now/Today'},{id: 'boy()',label:'Beginning of this year'}, {id: 'eoy()',label:'End of this year'}, {id: 'eoc()',label:'Beginning of this century'}]" 
					                        v-model="data[ col.name]"
					                        @input="alert(1)"
								            placeholder=''>  
								        </vSelect>
								        
				                   </span>
				                  </div>
		            		</td>
		            	</tr>
		            </table>
	
	              </tab-pane>
              	</tabs>
			  
			  
              <table v-if="message" width='100%'  :style="getAppStyle()+'height: 20pt;'">
	              <tr>
	              <td :class="msgClass">
	                {{ message }} 
					<span v-show="success" style='display: inline-flex; color: green; margin-left: 10pt;' id='showSucess'>
	                  	<mdicon style='color: green; padding-left: 10pt;' name="check-bold"/>
				    </span>
	              </td>
				  
	              </tr>
              </table>
              
              
              <table v-else width='100%' :style="getAppStyle()">
             
              <tr>
              <td width="20%" class='lUpdateInfo'>Last Update</td>
              <td><InputDateTime size="11pt" :disabled=true v-model=data.lUpdate /></td>
              
              </tr>
              <tr>
			  <td  class='lUpdateInfo'>Last User</td>
			  <td v-if="countChanges()" class='lUpdateInfo'>{{ lastUser.login }} <span class='lUpdateInfo' style="color: red;">{{countChanges()}} change(s)</span></td>
              <td v-else class='lUpdateInfo'>{{ lastUser.login }}</td>
                            
              </tr>
              </table>
			  </div> <!-- mainContent-->
			  </slot>
			  </div> <!-- modal-body-->
			  </div>
			  <!-- BUTTONS -->
			  <div :style="getAppStyle()+(style=='classic'?'width: 100% !important; position: absolute; bottom: 25pt;': 'width: 15% !important; height: 89%; position: absolute; right: 0; top: 56pt;')+'display: block; border-left: 1px solid '+getFGColor('#ddd','#444')+';'">
	  			     <div :style="(style=='classic'?'display: inline-flex; float: right;': 'display: block; position: absolute; right: 5pt; bottom: 10pt;')">
	  			      <div v-if="countChanges() == 0">
					    <button v-tooltip="'Close the current popup'" type="button" :style="getAppStyle()" class="button btn-secondary btn-close" @click="close()" data-dismiss="modal"><mdicon :size='20' name='close'/></button>
	  			        <button v-tooltip="'Reload displayed data for '+tr(metadata.name)" type="button" :style="getAppStyle()" class="button btn-secondary btn-reset"  @click.prevent="readDataRecord()"><mdicon :size='20' name='reload'/></button>
					    <button v-tooltip="'Save '+tr(metadata.name)" type="button" :style="getAppStyle()+''" class="button btn-primary btn-save" :disabled="!metadata.editable"  @click.prevent="update()"><mdicon :size='20' name='content-save'/></button>
					  </div>
					  <div v-else>
						<button v-tooltip="'Close the current popup'" type="button" :style="getAppStyle()" class="button btn-secondary btn-close needed" @click="close()" data-dismiss="modal"><mdicon :size='20' name='close'/></button>
						<button v-tooltip="'Reload displayed data for '+tr(metadata.name)" type="button" :style="getAppStyle()" class="button btn-secondary btn-reset needed"  @click.prevent="readDataRecord()"><mdicon :size='20' name='reload'/></button>
						<button v-tooltip="'Save '+tr(metadata.name)" type="button" :style="getAppStyle()+''" class="button btn-primary btn-save needed" :disabled="!metadata.editable"  @click.prevent="update()"><mdicon :size='20' name='content-save'/></button>					  
					  </div>
					 </div>
	  			     <div :style="(style=='classic'?'display: inline-flex; float: right; padding-top: 4pt; padding-right: 20pt;': 'display: block; position: absolute; text-align: center; width: 100%; top: 20pt;')" >
	  			      <button type="button" :style="getAppStyle()" class="button btn-nav" :disabled='visualIndex == 0'                  @click="first()">|&lt;</button>
	  			      <button type="button" :style="getAppStyle()" class="button btn-nav" :disabled='visualIndex <= 0'                  @click="left()" >&lt;</button>
	  			      <button type="button" :style="getAppStyle()" class="button btn-nav" :disabled='visualIndex+1 >= visualMapping.length' @click="right()">&gt;</button>
	  			      <button type="button" :style="getAppStyle()" class="button btn-nav" :disabled='visualIndex+1 == visualMapping.length' @click="last()" >&gt;|</button>
	  			     
	  			     </div>
	  			      <div :style="(style=='classic'?'display: inline-flex; float: right; padding-top: 4pt; padding-right: 20pt;': 'display: block; position: absolute; text-align: center; width: 100%; top: 60pt;')">
	  			      <div v-if="getCreatables(creatables, data).length" :style="(style=='classic'?'margin-right: 5pt;':'margin-bottom: 5pt;')">
						
						  <button v-for="(link,idx) in getCreatables(creatables, data)" type="button" class="button btn-nav" 
		  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"  
		  			             :disabled='link.disabled' :title="'Create new '+tr(link.entity)"
		  			             :key="'LNK_'+idx"  @click.prevent="newDetail(data, link.entity)"><span style="float: left;padding-bottom: 2pt;">
									<mdicon size="16" name="plus"/></span><span>{{tr(link.entity)}}</span></button>
					  </div>		 
	  			      <button v-if="metadata.name == 'Module'" type="button" class="button btn-nav btn-editor"
	  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
	  			             :disabled="!metadata.editable"  
	  			             key="EDIT"   @click.prevent="openEDITOR(data.name)">EDIT {{data.name}}</button>
	  			            
	  			      <button v-if="metadata.allowEditor" type="button" class="button btn-nav btn-editor"
	  			             :style="(style=='classic'?'display: inline-flex;':'display: block; width: 100%; padding-top: 2px; margin-bottom: 5pt; text-align: center;')+(isDarkMode()?getAppStyle()+'color: #fda;':'')"
	  			             :title="'change to EDIT mode, open visual editor for '+metadata.name"
	  			             key="EDIT_ADM"   @click.prevent="openEDITOR(metadata.name)"><span aria-hidden="true">
	  			             <mdicon  name='pencil-box-outline'/>
	  			             </span>
	  			      </button>
	  			     
	  			      <button v-if="metadata.name === 'History' || metadata.name === 'Mapping'" type="button" class="button btn-nav btn-editor"
	  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
	  			             key="HST_FLD_REC"   @click.prevent="openEntity(data.recordId, data.moduleId)">Show Record</button>
	  			      <button v-if="metadata.name === 'Files' && data.fileTypeValue==='PDF'" type="button" class="button btn-nav btn-editor"
	  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
	  			             key="PDF_FLD_REC1"   @click.prevent="$refs.pdfView.open(data.data, data.name, data.compressed)"><mdicon :width="20" name="file-pdf-box"/></button>
					  <button v-if="metadata.name === 'Invoices'" type="button" class="button btn-nav btn-editor"
 	  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
 	  			             key="PDF_FLD_REC1"   @click.prevent="$refs.pdfView.open(data.data, data.name, data.compressed)"><mdicon :width="20" name="file-pdf-box"/></button>
	  			      <button v-if="metadata.name === 'Files' && data.fileTypeValue!=='PDF' && !data.compressed" type="button" class="button btn-nav btn-editor"
	  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
							
	  			             key="PDF_FLD_REC2"   @click.prevent="$refs.pdfView.download(data.data, data.name)"><mdicon :width="20" name="download" /></button>
	  			      <button v-if="metadata.name === 'Files' && data.fileTypeValue!=='PDF' && data.compressed" type="button" class="button btn-nav btn-editor"
	  			            :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
	  			             key="PDF_FLD_REC2"   @click.prevent="downloadUnzip(data)"><mdicon :width="20" name="download-box-outline" /></button>
	  			      <button v-else type="button" class="button btn-nav"
	  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
	  			             :title="'show history/log for '+metadata.name"
	  			             key="HST_REC"   @click.prevent="showRecordHistory($event)">History</button>
	  			      <template v-if="metadata.functions && metadata.functions.length">
	  			          
	  			      <template v-for="(fds,idx) in metadata.functions"> <!-- .sort((a,b)=>(a.display.localeCompare(b.display)))"  > -->
	  			           <button :key="'fct'+idx" type="button" class="button btn-nav"  
	  			             :style="getAppStyle()+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')" 
							 :title="fds.toolTip"
	  			             @click.prevent="run(metadata, fds)">{{fds.display}}</button>
	  			           <button :key="'fctd'+idx" v-if="fds.resultFile" type="button" class="button btn-nav" :title="'download '+fds.resultFile.name"
						          :style="getAppStyle()+'color: green;'+(style=='classic'?'display: inline-flex;':'display: block; width: 100%; text-align: center;')"
	  			                   @click="download(fds.resultFile.id, 'download')">
	  					 		 <mdicon :width="16" :height="16" name="download" /> 
	  					  	 </button> 
	  				  	 </template> 
	  				  	 </template>        
	  			     </div>
	  			</div>
           
			
		 

      
    </div>
    
  </div>  
  <GFWEOpenEditor ref='editor' :path=currentPath @update='passUpdate' @closeUntilPosition="closeUntilPosition" @close="close"></GFWEOpenEditor>
  <PDFViewer name='pdfView' ref='pdfView' />
      
  <GFWEditorRouter v-show='showEditorDetail' 
    @close="closeEditorDetail" 
    @closeUntilPosition="closeUntilPosition"
    :parentMeta=currentParentMeta
    :parentId=currentParentId
    :entityMap=detailEntityMap
    :parentRecord=currentDataRecord
    :metadata="metadataDetail"
    :counter="detailCounter" 
    :path=currentPath
    :mapping=allDetailIDs
    :zIndex=incZIndex() 
    @update="passUpdate" @insert="passInsert"
    :time="0"
    :dataRecord=selectedDetail>
   </GFWEditorRouter>  
   <ErrorPopUp :errorType="errorTypePU" :error="errorPU"></ErrorPopUp>
   
   <ContextMenu ref="menu">
      <template v-if="contextData" slot-scope="{ contextData }">
        
        <ContextMenuItem v-if="contextData.col" @clicked="showHistory($event, contextData.col)">
          show history for {{contextData.col.display}}
        </ContextMenuItem>
                
        <ContextMenuSep v-if="contextData.col && contextData.col.editable"/>
        <ContextMenuItem v-if="contextData.col && contextData.col.editable" @clicked="$refs.menu.close(); setFromClipboard( contextData.col)">
          Copy from clipboard to {{contextData.col.display}}
        </ContextMenuItem>

       </template>
    </ContextMenu>
    <PhaseEditor :ref="'phaseEdit'" />
    <GConfirm ref='confirm'/>
    <HistoryPopup :name="metadata.name+'_'+data.id" ref='HistoryPopup' @reEnter=reEnter></HistoryPopup>
    <GFWEditorInventory :ref='getInvEditorId()' ></GFWEditorInventory>
    <ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
  </div>
</template>

<script>
import { getAppStyle, setDarkMode, isDarkMode, initAppMode,getFGColor } from '@/AppStyle.js';
import {HTTP, fwAPI, opsAPI, showError, gridAPI} from '@/variables.js'
import { tr } from '@/translate.js';
import GLink from '@/components/GLink';
import ProgressBar from '@/components/ProgressBar';
import GConfirm from '@/components/GConfirm';
import GSelect from '@/components/GSelect';
import GSelectM from '@/components/GSelectM';
import InputOptionList from '@/components/inputElements/InputOptionList';
import GSelectModel from '@/components/GSelectModel';
import InputText from '@/components/inputElements/InputText';
import InputTextISODur from '@/components/inputElements/InputTextISODur';
import InputTextARR from '@/components/inputElements/InputTextARR';
import InputDynDate from '@/components/inputElements/InputDynDate';
import InputColour from '@/components/inputElements/InputColour';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import GImage from '@/components/inputElements/InputImage';
import GFile from '@/components/GFile';
import Shares from '@/components/booking/Shares4GFWE';
import WeekLimits from '@/components/booking/WeekLimits';
import PhaseEditor from '@/components/inputElements/PhaseEditor';
import InputSecret from '@/components/inputElements/InputSecret';
import InputColumnHeader from '@/components/inputElements/InputColumnHeader';
import InputEnumList from '@/components/inputElements/InputEnumList';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InputDouble from '@/components/inputElements/InputDouble';
import InputMoney from '@/components/inputElements/InputMoney';
import InputJSON from '@/components/inputElements/InputJSON';
import InputURL from '@/components/inputElements/InputURL';
import InputDateTime from '@/components/inputElements/InputDateTime';
import GFWEditorInventory from '@/components/GFWEditorInventoryInline';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import WeekAndTimeLimits from '@/components/booking/WeekAndTimeLimits'
import HouseNumbers from '@/components/booking/Housenumbers'
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import Switch from '@/components/Switch'
import ErrorPopUp from '@/components/ErrorPopUp';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import { Tabs, TabPane } from 'vue-bulma-tabs'
import JQuery from "jquery";
let $ = JQuery;
import PDFViewer from '@/components/PDFViewer';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import HistoryPopup from'@/components/HistoryPopup.vue';
var timers = [];
const EMPTY_COL = {visible: true, editor: "String", display: ''};
const EMPTY_FIELD = { name: "", display: "", empty: true, visible: true, span: 1, data: EMPTY_COL };
const SEPARATOR_FIELD = { name: "separator", display: "", empty: true, visible: true, span: 1, data: EMPTY_COL };
const LABEL_FIELD = { name: "label", display: "", empty: true, visible: true, span: 1, data: EMPTY_COL };
export default {
  name: "GFWEditorDefault",
   components : {
	GFWEditorInventory, ProgressBar, GConfirm,PhaseEditor,Shares,WeekLimits,InputTextARR,WindowCloseBN,InputURL,InputTextISODur, HouseNumbers,
    ContextMenu, ContextMenuItem, ContextMenuSep, HistoryPopup, InputMoney, PDFViewer, GFile, InventoryFilterEditor, WeekAndTimeLimits,
    InputText, 'app-switch': Switch, InputSecret,InputDateTime, InputDatePick, InputInt, Tabs, TabPane, InputTimezone, GImage, GLink,InputEnumList, InputOptionList,
    GSelectM, GSelect, InputDouble, InputDynDate, ErrorPopUp, InputTime, InputWeekdays, GSelectModel, InputColour, vSelect, InputColumnHeader, InputJSON
  },
  props: {
    metadata: Object,
	style: { type: String, default: 'xclassic' },
    dataRecord: Object,
    parentMeta: Object,
    parentId: { type: Number, default: 0 },
    parentRecord: Object,
    time: Number,
    counter: Number,
    zIndex: { type: Number, default: 9 },
    dataArray: { type: Array, default: () => [] },
    entityMap: { type: Object, default: () => {} },
    detailEntityMap: { type: Object, default: () => {} },
    mapping: { type: Array, default: () => [] },
    selectedIndex: { type: Number, default: 0 },
    dataId: { type: Number, default: 0 },
    path: { type: Array, default: () => [] },
    display: Boolean
  },
  data(){
    return {
		tabLayout: ['top','left','bottom','right'],
		tabLayoutIDX: 0,
        folderDefinition: [],
        success: false,
        index: this.selectedIndex,
        creatables: [],
        data: {},
		original: {},
        recordId: this.dataId,
        selectedTab: 0,
        visualMapping: Array,
        visualIndex: 0,
        numberOfRows: 0,
        lastUser: {},
        width: 70,
        height: 80,
        copyToColumn: [],
        mainHeight: 75,
        allDetailIDs: [],
        mark: [],
        shFilterCount: 0,
        reloaded: false,
        selectedDetail: {},
        metadataDetail: {},
        detailCounter: 0,
        showEditorDetail: false,
        msgClass: "msgDefault",
        message: "",
        errorPU: {},
        errorTypePU: "",
        updateCount: 0,
		currentDataRecord: {},
        currentParentId: 0,
        currentParentMeta: {},
        currentPath: [],
        pdf: "",
        getAppStyle, isDarkMode, tr, getFGColor,
        myId: Math.round(Math.random()*100000000),
        // progBar
        showProgressBar: false,
        pbAction: null, 
        pbTitle: null, 
        pbExec: null,
        popupData : {
          "header" : {},
          "body" : {},
          "data" : {}
        },
     }
    },
  methods: {
	    getInvEditorId() {
	    	return 'INVEDIT'+this.myID; 
	    },
	    getInvEditor() {
	    	if ( Array.isArray(this.$refs[this.getInvEditorId()]))
			{
				return this.$refs[this.getInvEditorId()][0];
			}
			else
			{
				return this.$refs[this.getInvEditorId()];
			}
	    },
	    sortDisplay(arr)
	    {
	    	if ( !arr)
	    	{
	    		return arr;
	    	}
	    	
	    	arr.sort((a,b)=>(a.display.localeCompare(b.display)));
	    	return arr;
	    },
        openEDITOR( entity) {
            let that = this;
            this.showEditorDetail = false;
            //alert(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+entity+"/definition")
            HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+entity+"/definition")
                    .then( response => 
                    {
                    	this.metadataDetail = response.data.metadata;
                    	this.selectedDetail = response.data.module;
			        	this.metadataDetail.editor = 'GFWEditorEditor';
			        	this.metadataDetail.editable = true;
			        	this.currentPath = this.addPath();
			        	
			        	this.showEditorDetail = true;
			        }).catch(e => {
                     that.error("can't open editor "+entity, e);
                });
        },
        openPhaseEditor( line, name, solver )
        {
       	 this.$refs['phaseEdit'].open( line, name, solver);
        },
        getClipboard()
        {
        	navigator.clipboard
        	  .readText()
        	  .then((clipText) => {return clipText;});
        	//return true;
        	 
        },
        setFromClipboard( col)
        {
        	let that=this;
        	navigator.clipboard
	      	  .readText()
	      	  .then((clipText) => {that.data[ col.column]= clipText;});

        },
        setInventory(data)
        {
        	this.data = {... data}
        },
        showHistory(event, col)
        {
        	this.$refs.menu.close();
        	let that = this;
        	let url = "/show/history/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.id+"/"+this.data.id+"/"+col.id+"/"+col.column;
        	let reader = new Promise(function (resolve, reject) {
	        	HTTP.post( fwAPI+url )
		        		 .then( response => 
		                 {
	
		               		let data = response.data;
		               		let txt = new Array();
		               		let time = "";
		               		for ( var x in data)
		               		{
		               		    let change = "";
		               		    if ( data[x].changes )
		               		    {
		               				change = JSON.parse(data[x].changes)[col.column];
		               				time = new Date(data[x].lUpdate).toUTCString();
		               			}
		               			else
		               			{
		               				change = JSON.parse(data[x].snapshot)[col.column];
		               				time = new Date(data[x].lUpdate).toUTCString();
		               			}
		               			
		               			txt.push({id: data[x].id, change: change, record: data[x]});
		               		}
		               		that.popupData.body = txt;
		               		that.popupData.data = { name: that.data.visualDisplay, id: that.data.id };
		               		that.popupData.header = { entity: that.metadata.name, column: col };
		               		resolve (that.popupData);
		                          
		                 }).catch(e => {
		                        console.log( JSON.stringify(e));
		                        that.$toast.error("loading data: " + e.response.data, 'Error', { position: "topRight" });
								reject();
		                    });
		         });
	         reader.then( data => { that.$refs.HistoryPopup.open(data);   })   
	
        },
		countChanges()
		{
			let changes = 0;
			for ( let c in this.original)
			{
				let val = this.original[ c];
				if ( !val && !this.data[ c])
				{
					continue;
				}
				if ( val != this.data[ c] )
				{
					changes ++;
				}
			}
			return changes;
		},
        showRecordHistory(event)
        {
        	this.$refs.menu.close();
        	let that = this;
        	let reader = new Promise(function (resolve, reject) {
        	    let url = "/show/history/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+that.metadata.id+"/"+that.data.id;

	        	HTTP.post( fwAPI+url )
		        		 .then( response => 
		                 {
							
		               		let data = response.data;
		               		let txt = new Array();
		               		for ( var x in data)
		               		{
		               			let change = data[x].changes;
		               			
		               			let time = new Date(data[x].lUpdate).toUTCString();
		               			txt.push({id: data[x].id, change: change, record: data[x]});
		               		}
		               		that.popupData.body = txt;
		               		that.popupData.data = { name: that.data.visualDisplay, id: that.data.id };
		               		that.popupData.header = { entity: that.metadata.name };
		               		resolve (that.popupData);
		                        
		                 }).catch(e => {
		                        //console.log( JSON.stringify(e));
		                        that.$toast.error("loading data: " + e.response.data, 'Error', { position: "topRight" });
								reject();
		                    });
		           });
		    reader.then( data => { that.$refs.HistoryPopup.open(that.popupData);   })            
	
        },
        openInvEditor( filter, record, columnName, forceOpen)
        {
        	//alert("GFWE"+JSON.stringify(record)+columnName)
        	this.getInvEditor().open( filter, record, columnName, forceOpen, this.setInventory);
        },
        openInvEditorShare( filter, record, columnName, forceOpen)
        {
      		try {record[columnName] = JSON.parse(record[columnName]);} catch(e) {
				  //
			  }
      		this.getInvEditor().open( filter, record, columnName, forceOpen, this.setInventoryShare);
        },
        setInventoryShare( filter, record, column)
        {
		    record[column] = JSON.stringify(filter[column]);
		    this.data = {... this.data}
		    this.shFilterCount++;
        },
        reEnter( column, value)
        {
			
        	if ( column )
        	{
				try {
					this.data[ column] = JSON.parse(value);
				} catch (e) {
					this.data[ column] = value;
				}
        		
        		this.mark[ column] = true;
        		timers.push( setTimeout(this.hideSuccess, 2000));
        		this.$forceUpdate();
        	}
        	else
        	{
        		let rec = value.changes ? value.changes: value.snapshot;
        		//alert( rec);
        		let obj = JSON.parse(rec);
        		for ( var col in obj )
        		{
        		    let c = this.findColumn( col);
        		    //alert(c.editable)
        		    if ( c.editable)
        		    {
        				this.data[ col] = obj[ col];
        				//alert( obj[ col]);
        				this.mark[ col] = true;
        				
        			}
        		}
        		timers.push( setTimeout(this.hideSuccess, 2000));
        		this.$forceUpdate();
        	}
        },
        nodeToOpen( event, data, moduleId, dataId)
	    {
	        
	    	this.selectedDetail = {};
	    	this.metadataDetail = {};
	    	//alert(metadata.name)
	    	let that = this;
	    	let time = new Date().getTime();
	    	let mapperLoad = new Promise(function (resolve, reject) {
		    
			//alert( data.moduleId +"/" +JSON.stringify(data))
			//console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+moduleId+"/allEntitiesWithDate/2022-02-02/true/1");
			//alert( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+moduleId+"/allEntitiesByIdWithDate/null/true/1")
	    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+moduleId+"/allEntitiesByIdWithDate/null/true/1", [dataId])
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		//console.log("LOADED rights in " + dur +"ms");  
	                    Object.assign( that.selectedDetail, response.data.data[0]);
	                    that.metadataDetail = response.data.metadata;
	                    that.detailEntityMap = response.data.entity2DataMap;
	                    this.currentPath = this.addPath();
	                    
	                    that.showEditorDetail = true;
	                          
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    	});
	    	
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    },
	    menuRClicked(event, tab, tabIdx, col, colIdx)
	    {
	        event.preventDefault();
	        //alert( JSON.stringify(col))
	        if ( col )
	        {
	        	this.$refs.menu.open(event, {tab: tab, col: this.findColumn(col.name)} )
	        }
	        else
	        {
	        	this.$refs.menu.open(event, {tab: tab} )
	        }
	    },
        name() { return "FWViewer"+this.zIndex; },
        close() {this.selectedTab = 0; this.getInvEditor().close(); this.popupData.display = 'none'; this.$emit('close')},
        copy(r) { let aCopy = {}; Object.assign( aCopy, r); return aCopy; },
        getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
        getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
        incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
        decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
        maximize() { 
			this.adjustSizes( true);
		},
        isStringArr(column) { return column.editor == 'STRING' && column.type.endsWith("[]"); },
		isString(column) { return column.editor == 'STRING'; },
        isTz(column) { return (column.editor === 'TIMEZONE') || (column.editor === 'SELECT' && column.visualDetail == 'TZ' && !column.array); },
        isLink(column) { return column.editor === 'LINK'; },
        isSelect(column) {
            let b = column.editor == 'SELECT' && column.visualDetail != 'TZ' && !column.array;
        	return b; 
        },
        isMSelect(column) { return column.editor === 'SELECT' && column.visualDetail != 'TZ' && column.array; },
        isInt(column) { return column.editor === 'INT'; },
        isDouble(column) { return column.editor === 'DECIMAL'; },
		isURL(column) { return column.editor === 'URL'; },
        isMoney(column) { return column.editor === 'MONEY'; },
        isJSON(column) { return column.type === 'json'; },
        isShare(column) { return column.editor === 'SHARES'; },
        isWeekLimits(column) { return column.editor === 'WEEKLIMITS'; },
		isWeekShareLimits(column) { return column.editor === 'WEEKSHARELIMITS'; },
        isSecret(column) { return column.editor === 'SECRET'; },
        isColour(column) { return column.editor === 'COLOUR'; },
        isModel(column) { return column.editor == 'SELECTMODEL'; },
        isColumnHeader(column) { return column.editor === 'COLUMNHEADER'; },
		isEnumList(column) { return column.editor === 'ENUMLIST'; },
		isOptionList(column) { return column.editor === 'OPTIONLIST'; },
        isInventoryFilter(column) { return column.editor === 'INVENTORY'; },
        isPhaseDef(column) { return column.type === 'phaseDef'; },
		isISODur(column) { return column.type === 'duration'; },
        isBool(column) { return column.editor === 'BOOLEAN'; },
        isTime(column) { return column.editor === 'TIME'; },
		isHousenumbers(column) { return column.type === 'json' && column.column == 'housenumbers'; },
        isWeekday(column) { return column.editor === 'WEEKDAYS'; },
        isDynamicDate(column) { return column.editor === 'DYNDATE'; },
        isDate(column) { return column.editor === 'DATE'; },
        isDatetime(column) { return column.editor === 'DATETIME'; },
        isImg(column) { return column.editor === 'IMAGE';},
        isFile(column) { return column.editor === 'FILE';},
        mightBeDynSelect(column) { return (column.editor === 'VSELECT');},
        isDynMSelect(column) { let b = (column.editor === 'VSELECT' && this.getDynMLink(column)); return b },
        isDynSelect(column) { let b = (column.editor === 'VSELECT' && this.getDynLink(column)); return b },
        isDynDate(column) { let b = (column.editor === 'VSELECT' && this.getDynDates(column)); return b },
		isAdmin() {return sessionStorage.isAdmin === 'true'; },
        getParentMeta() { return this.parentMeta; },
        getCreatables( links, data)
        {
        	var newArray = [];
        	for ( var i in links )
        	{
        		if ( ! links[i].createIf)
        		{
        			newArray.push( links[i]);
        		}
        		else 
        		{
        			if ( data[ links[i].createIf ] )
        			{
        				newArray.push( links[i]);
        			}
        		}
        	}
        	return newArray;
        },
        getDynLink(column) {
       		//alert( JSON.stringify(column.vselect));
       		var fieldValue = this.data[ column.column]; 
       		if (typeof fieldValue === 'string' || fieldValue instanceof String)
       		{
       		    try {
			        JSON.parse( fieldValue);
			    } catch (e) {
			        return null;
			    }
       			
       		}
            let selectField = this.metadata.columns.find( p => p.column === column.vselect);
            //alert( JSON.stringify(selectField));
            //alert( this.data[selectField.column]);
            let type = this.data[ 'type' ];
        	let referencedId = parseInt( this.data[selectField.column]);
        	//alert(referencedId);
        	//alert(column.vselect);
        	try {
	        	let lnk = this.findColumn( column.vselect).select;
	        	//alert(lnk);
	        	//alert(this.entityMap[lnk].data);
		        let mySelect = this.entityMap[lnk].data.find( m => m.id == referencedId);
		        //let linkData = {entity: mySelect.label, name: mySelect.label };
		        //alert( "y -> "+JSON.stringify(type));
		        return type && !type.endsWith("[]") && mySelect;
	        } catch (e)
	        {
	        	return false;
	        }
        },
        downloadUnzip( data)
        {
        	let that = this;
        	if ( data.compressed )
        	{
        		let load = new Promise(function (resolve, reject) {
                    HTTP.get( gridAPI+"/unzip/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+data.id)
                		 .then( response => 
                         {
                        	 let unzippedFile = response.data;
                        	 that.$refs.pdfView.download(unzippedFile.data, data.name);
                        	 resolve (true);
                         }).catch(e => {
                             that.$toast.error("unzip file-data: " + e.response.data, 'Error', { position: "topRight" });
                            
                             reject ();
                         });
        	      });
        	}
        	else
        	{
        		this.$refs.pdfView.download(data.data, data.name);
        	}
        },
     
        getDynMLink(column) {
       		let selectField = this.metadata.columns.find( p => p.column === column.vselect);
       		//alert( JSON.stringify(selectField));
       		let typeField = this.metadata.columns.find( p => p.column == 'type');
       		let type = this.data[ 'type' ];
       		try {
	            let referencedId = parseInt( this.data[selectField.column]);
	        	let lnk = this.findColumn( column.vselect).select;
	        	let mySelect = this.entityMap[lnk].data.find( m => m.id == referencedId);
	        	//alert( "x -> "+JSON.stringify(type));
		        return type && type.endsWith("[]") && mySelect;
	        } catch (e)
	        {
	        	return false;
	        }
        },
        getDynDates(column) {
       		let selectField = this.metadata.columns.find( p => p.column === column.vselect);
       		let typeField = this.metadata.columns.find( p => p.column == 'type');
       		let type = this.data[ 'type' ];
       		
            let referencedId = parseInt( this.data[selectField.column]);
        	let lnk = this.findColumn( column.vselect).select;
        	let mySelect = this.entityMap[lnk].data.find( m => m.id == referencedId);
        	
	        let b= type && type.startsWith("date") && selectField && !mySelect;
	        //alert(column.column + " " +b);
	        return b;
        },
        useCopyFrom( column)
        {
        	let value = this.data[column];
        	//alert( column + "; " + value);
        	for ( var i in this.copyToColumn[column])
        	{
        		let copyTo = this.copyToColumn[column][i];
        		if ( (! this.data[ copyTo ]) || (this.data[ copyTo ] == this.findColumn( copyTo ).defaultValue))
        		{
        			this.data[ copyTo ] = value;
        		}
        	}
        	
        },
        onSelect(column) 
        { 
        	for ( var disableMe in this.metadata.onSelect )
            {
            	if ( column == this.metadata.onSelect[ disableMe ].second )
	        	{
	        		//alert("ONSELECT"+disableMe);
	        		this.findColumn( this.metadata.onSelect[ disableMe ].first).editable = !! (this.data[column]);
	        		if ( ! this.data[column] && this.data[this.metadata.onSelect[ disableMe ].first] )
	        		{
	        			this.data[this.metadata.onSelect[ disableMe ].first]=0;
	        		}
	        	}
            }
        	
        },
        checkFolder(folderDefinition)
        {
        	let f = [];
        	for ( let tab in folderDefinition)
            {
                	//alert( tab);
                	//alert( folderDefinition[tab].display);
                	f.push(folderDefinition[tab])
            }
            return f;
        },
        clearStructure()
        {
        	for ( let f in this.folderDefinition )
        	{
	        	let rows = this.folderDefinition[f].rows;
	        	let cleanRows = new Array();
	        	for ( let r in rows )
	        	{
	        		let row = rows[r];
	        		if ( row )
	        		{
	        			for ( let c in row )
	        			{
	        		    	let col = row[c];
	        				if ( ! col )
	        				{
	        					row[c] = EMPTY_COL
	        				}	
	        			}
	        			cleanRows.push(row);
	        		}
	        	}
	        	this.folderDefinition[f].rows = cleanRows;
	        }
	        this.selectedFolder = this.folderDefinition[this.selectedTab];
        },
        createStructure( folder)
        {
        	if ( !folder.folderStructure)
        	{
        		let structure = { id: folder.id, rows: [] };
        		let row = [];
        		
        		for ( let c in folder.columns )
        		{
        			let col = folder.columns[c];
        			row.push( {name: col, span:1, empty: false, visble: true});
        			if ( row.length >= folder.cells )
        			{
        				structure.rows.push( row);
        				row = [];
        			} 
        		}
        		if ( row.length > 0 )
    			{
    				structure.rows.push( row);
    			} 
        		return structure;
        	}
        	return folder.folderStructure;
        },
        calcData()
	    {
	    	try{
	    	let that = this;
	    	//alert("calcData() >"+that.metadata);
	        that.folderDefinition = new Array();
	        for (let f in that.metadata.folder)
	    	{
	    	 	let fld = that.metadata.folder[f];
	    	 	//alert("fld() >"+fld.name + "-->"+fld.folderStructure );
	         	let structure = fld.folderStructure;
	         	if ( !structure)
	         	{
	         		fld.folderStructure = this.createStructure( fld);
	         		//structure = fld.folderStructure;
	         	}
	         	if ( structure)
	         	{
		         	structure.folder = fld.name;
		         	structure.display = fld.display;
		         	structure.cells = fld.cells;
		         	//alert("fld() >"+JSON.stringify(structure));
		         	let cleanRows = new Array();
		         	for ( let r in structure.rows)
		    	 	{
		    	 		let row = structure.rows[r];
		    	 		
		    	 		if ( row && row !== null )
		    	 		{
		    	 			
			    	 		for ( let c in row)
			    	 		{
			    	 			let col = row[c];
			    	 			if ( col && typeof col === 'object' && col != null )
			    	 			{
			    	 				
				    	 			if ( col.name === SEPARATOR_FIELD.name )
			        	 			{
			        	 				col.visible = true;
			        	 				col.display= '<separator>'
			        	 				col.data = {};
			        	 				Object.assign( col.data, EMPTY_COL);
			        	 				
			        	 			}
			        	 			else if ( col.name === LABEL_FIELD.name )
			        	 			{
			        	 				col.visible = true;
			        	 				col.display= '<label>'
			        	 				col.data = {};
			        	 				Object.assign( col.data, EMPTY_COL);
			        	 				
			        	 			}
				    	 			else if ( col.empty )
				    	 			{
				    	 				//alert( JSON.stringify(col));
				    	 				row[c] = EMPTY_FIELD;
				    	 				//alert( JSON.stringify(row[c] ));
				    	 			}
				    	 			else 
				    	 			{
				        	 			let column = that.findColumn(col.name);
				        	 			col.data = column;
				        	 			col.visible = column.visible;
				        	 			//alert(col.span)
				        	 		}
				        	 	}
				        	 }
				        	 cleanRows.push(row);
				        	 //alert(row)
		    	 		}
		    	 	}
		    	 	structure.rows = cleanRows;
		    	 	that.folderDefinition[f] = structure;
		    	 	
		    	 }
	    	}
	    	
            if ( that.folderDefinition && that.folderDefinition.length > 0 )
            {
                this.clearStructure();
                
                //console.log("------");
                //console.log(JSON.stringify(that.folderDefinition))
                //console.log("------");
            	return;
            }
	        
			let folderDef = new Array();
        	
        	let tab = null;
        	for ( tab in that.metadata.folder)
        	{
        		
        		let folder = that.metadata.folder[tab];
        		//alert( folder.name+":"+folder.cells);
        		let cols = folder.columns;
        		let x = 0;
        		let y = 0;
        		let col = null;
        		let rowArr = new Array();
        		let columnArr = new Array();
        		for ( col in cols )
        		{
        			let column = that.findColumn(cols[col]);
        			
        			if ( x >= folder.cells  )
        			{
        				x = 0;
        				y++;
        				columnArr = new Array();
        			}
        			if ( this.isLink(column) || (y > 0 && x > 0 && column.newline === true) )
        			{
        				x = 0;
        				y++;
        				columnArr = new Array();
        			}
        			if ( ! column || (! column.display && !this.isLink(column))  )
        			{
        				columnArr[x] = EMPTY_FIELD;
        			}
        			else 
        			{
        				if ( this.isLink(column) )
        				{
        					columnArr[x] = { name: column.name, empty: false,  visible: column.visible, span: folder.cells, data: column };
        				}
        				else
        				{
        					columnArr[x] = { name: column.column, empty: false,  visible: column.visible, span: column.span, data: column };
        				}
        			}
        			rowArr[y] = columnArr;
        			//alert(JSON.stringify(rowArr[y]))
        			if ( column.span )
        			{
        				x += column.span;
        			}
        			else
        			{
        				x++;
        			}
        			//console.log(column.display +"/"+col+" --> " + x)
        		}
				
        		if ( x >= 0 && x < folder.cells )
    			{
    				columnArr[x] = EMPTY_FIELD;
    				rowArr[y] = columnArr;
    			}
        		let idx = 0;
    			columnArr = new Array();
    			for ( idx = 0; idx < folder.cells; idx++)
    			{
    				columnArr[idx] = EMPTY_FIELD;
    			}
    			rowArr[y+1] = columnArr;
        		folderDef[tab] = {id: folder.id, folder: folder.name, display: folder.display, cells: folder.cells, rows: rowArr}
        		 
        	}
        	//console.log(JSON.stringify(folderDef))
        	that.folderDefinition = folderDef;
        	//alert( JSON.stringify(folderDef));
        	}
        	catch( e)
        	{
        		console.log( e);
        		//alert( 'x'+ JSON.stringify(e));
        	}
        },
        isEmpty(columnArr)
        {
        	for ( let c in columnArr )
        	{
        		if ( ! columnArr[c].empty === true )
        		{
        			return false;
        		}
        	}
        	return true;
        },
        
        error( txt, err)
	    {
	      if ( err && err.response && err.response.data )
	      {
	        let info = err.response.data;
	        try
	        {
				
		        var scanner = new RegExp("[\\[]([a-zA-Z_0-9]*)[\\]]([.]*)");
		        var myArray = scanner.exec(info);
		       
		        if ( myArray[1] )
		        {
		            info = info.substring(myArray[1].length+2);
		        	this.mark[ myArray[1]] = true;
		       		//alert( myArray[1] + "-->"+ JSON.stringify(this.findColumn( myArray[1])));
		      		this.printMsg( err.response.status, txt+ ": " + info);	
		      	}
		      	else
		      	{
		      		this.printMsg( err.response.status, txt+ ": " +err.response.data);	
		      	}
		     }
		     catch(e) {
		     	this.printMsg( err.response.status, txt+ ": " +err.response.data);	
		     }
	      }
	      else 
	      {	
	      	this.errorTypePU=txt;
	      	if ( ! err )
	      	{
	        	this.errorPU = {'message': ""};
	      	}
	      	else
	      	{
	      		this.errorPU = err;
	      	}
	      }
	    },
        getDisplay( column) 
        { 
            if (this.visible(column.data)) 
            {
            	return column.data.display; 
            }
            
            return "";
        },
        visible(column) { return column && column.visible;},
        getModalStyle() { return "'position: relative; z-index: " + (9+this.zIndex)+";'"; },
        incZIndex() { return parseInt(this.zIndex)+1; },
        clearMsg()
        {
        	this.message = "";
        	this.mark=[];
        },
        printMsg(status, msg)
        {
        	if ( parseInt(status) != 200 )
        	{
        		this.msgClass = "msgError";
        		this.$toast.error( msg, 'Error', { position: "topRight" });
        	}
        	else
        	{
        		this.msgClass = "msgDefault";
        	}
        	timers.push( setTimeout(this.hideSuccess, 4000));
        	this.message = msg;
        },
        findColumn(column) { 
           //console.log( column );
           var colObject=this.metadata.columns.find(p => p.column === column); 
           //console.log( colObject );
           if ( colObject ) { 
       
              return colObject; 
           } 
           else { 
              colObject=this.metadata.links.find(p => p.name === column); 
              if ( colObject ) { 
                colObject.visible = true;
              	return colObject; 
              } 
              else {
                return {};
              }
           }
        },
        addPath()
        {
        	var p = [...this.path];
        	p.push({name: this.data.visualDisplay, entity: this.metadata.name, position: p.length});
        	return p;
        },
        newDetail(data, link)
        {
        	//console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/new/"+link );
        	if ( !data.id || ! data.id > 0 )
        	{
        		this.error("Please, save the data first", "");
        		return;
        	}
        	let myLink = this.metadata.links.find( l=>l.entity == link);
        	let fromEntity = this.metadata.name;
        	let parentID = data.id;
        	let parentMeta = this.metadata;
        	let parentRecord = data;
        	if ( ! myLink && this.parentMeta  )
        	{
        		myLink = this.parentMeta.links.find( l=>l.entity == link);
        		fromEntity = this.parentMeta.name;
        		parentID = this.parentId;
        		parentMeta = this.parentMeta;
        		parentRecord = this.parentRecord;
        	}
			let that = this;
			HTTP.post( fwAPI+"/createNewDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+link, parentRecord)
                    .then( response => {
                    that.selectedDetail = {};
                    Object.assign(that.selectedDetail, response.data.data);
                    
                    that.$set( that.selectedDetail, "tags", new Array());
                    that.metadataDetail =  response.data.metadata;
					//console.log( JSON.stringify( that.metadataDetail.folder))
                    
                    that.detailEntityMap = response.data.entity2DataMap;
                    //console.log( JSON.stringify( this.detailEntityMap ));
                    that.currentParentId = parentID;
					that.currentParentMeta = parentMeta;
					that.currentDataRecord = parentRecord;
					
					that.detailCounter++;
					that.showEditorDetail = true;
                    //
                }).catch(e => {
                     that.error("can't create new "+link, e);
                });
        	
        },
        action( what, myId, api)
        {
        	//console.log(what+"/"+ myId+"/"+api)
       		this[what](myId, api)
        },
        run(metadata, fds)
        {
        	
       		let that = this;
			if ( fds.needsField)
			{
				this.$iosPrompt({
					      text: 'Enter '+fds.needsField,
					      placeholder: ''
					    }).then( r => {
							if ( r )
							{
								that.pbExec = { meta: metadata, fds: fds, field: r};
				        		that.pbAction = "runInternal";
				        		that.pbTitle = "run "+fds.display.toLowerCase()+ " "+that.data.visualDisplay+"";
				        		that.showProgressBar = true;
							}
					    })        	
			}
			else
			{
	       		that.$iosConfirm( fds.toolTip).then(x => { 
	       			
	        	//this.$refs.confirm.confirm( {text: fds.display, title: "Run", button1: "Cancel", button2: "Ok"}).then(x => {
	            	
	        		that.pbExec = { meta: metadata, fds: fds};
	        		that.pbAction = "runInternal";
	        		that.pbTitle = "run "+fds.display.toLowerCase()+ " "+that.data.visualDisplay+"";
	        		that.showProgressBar = true;
	        	});
			}
        },
        runInternal(myId, api)
        {
        	let metadata = this.pbExec.meta;
        	let fds = this.pbExec.fds;
            let fromEntity = metadata.name;
            let that = this;
			let runObject = { json: JSON.stringify(this.data), neededValue: this.pbExec.field}
           
            api.post( fwAPI+"/runFunction/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+this.data.id+"/"+fds.functionName+"/"+myId, runObject, {timeout: 30000000})
                    .then( response => {
                       let actionResult = response.data;
                       that.showProgressBar = false;
                       if ( actionResult.resultFile )
                       {
                    	   fds.resultFile = actionResult.resultFile;
                       }
                       that.$toast.success(fds.name + ": "+  actionResult.message, 'Ok', { position: "topRight" });
                       if ( actionResult.newChild )
                       {
						   that.selectedDetail = {};
                    	   Object.assign(that.selectedDetail, actionResult.newChild);
						   this.$emit("insert", that.metadata.name, actionResult.newChild);
                           that.$set( that.selectedDetail, "tags", new Array());
                           that.metadataDetail =  actionResult.metadata ? actionResult.metadata: metadata;
						   that.metadataDetail = {...that.metadataDetail};
                           that.detailEntityMap = that.entityMap;
						   
                           that.allDetailIDs = [that.selectedDetail.id];
                           that.currentParentId = that.data.id;
       					   that.currentParentMeta = metadata;
       					   that.currentDataRecord = that.record;
       					
       					   that.detailCounter++;
       					   that.showEditorDetail = true;
                       }
                       else if ( actionResult.parent )
                       {
                    	   Object.assign(that.data, actionResult.parent);
                       }
                    //
                }).catch(e => {
                	 this.showProgressBar = false;
                	 if ( e.response.data.includes("cancelled") )
                	 {
                		 that.$toast.info(fds.display.toLowerCase()+ " "+that.data.visualDisplay+" cancelled", 'Ok', { timeout: 1500, position: "topRight" });
                	 }
                     else
                     {
                     	showError(that.$toast, e, e.response.data);
                     }
                });
        },
        openLink(id, link, allIds)
        {
        	
        	//console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/"+id+"/"+link );
        	let lnk = this.metadata.links.find(l=>l.name===link);
        	//alert( JSON.stringify(lnk));
        	this.allDetailIDs = [];
        	if ( allIds )
        	{
				for ( var x in allIds )
				{
					this.allDetailIDs.push({row: x, dataId: allIds[x], rId: allIds[x], visible: true });
				}
			}
			else
			{
				this.allDetailIDs.push({row: 0, dataId:id, rId: id, visible: true });
			}
			
			try {
			this.currentParentId = this.visualMapping[ this.visualIndex ].dataId;
			this.currentParentMeta = this.metadata;
			} catch (e) {
			//alert( JSON.stringify(e));
			}
			HTTP.post( fwAPI+"/getLinkedDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/"+id+"/"+link)
                    .then( response => {
                    this.selectedDetail = response.data.data;
                    this.metadataDetail =  response.data.metadata;
                    this.detailEntityMap = response.data.entity2DataMap;
                    //console.log( "OPEN-"+link+ " " +JSON.stringify( this.detailEntityMap));
                    this.currentPath = this.addPath();
                  
					this.showEditorDetail = true;
					//alert( JSON.stringify(this.selectedDetail));
                    //
                }).catch(e => {
                        this.error("can't open "+this.metadata.name, e);
                });
        	
        },
        download( fileId, doWhat )
        {
     	let that = this;
     	if ( doWhat == 'download')
         {
 	    	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
 	        .then( response => 
 	        { 
                 let downloadResponse = response.data;
            		that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
         	}).catch(e => (showError( that.$toast, "file download error", e)))
         }
         else
         {
         	
         	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
 	        .then( response => 
 	        { 
                
                 that.$refs.pdfView.open(response.data.file)
            		
         	}).catch(e => (showError( that.$toast, "file download error", e)))
         }

     },
     forceFileDownload(base64, name) 
 	{  
       const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
       const link = document.createElement('a');
       link.href = url
       link.setAttribute('download', name)
       document.body.appendChild(link)
       link.click()
     },
     base64ToArrayBuffer(base64) {
 	    var binary_string = atob(base64);
 	    var len = binary_string.length;
 	    var bytes = new Uint8Array(len);
 	    for (var i = 0; i < len; i++) {
 	        bytes[i] = binary_string.charCodeAt(i);
 	    }
 	    return bytes.buffer;
 	   },
        openEntity( id, name)
	    {
	    	this.currentPath = this.addPath();
	       
	        this.$refs.editor.open( name, id);
	        /*
	        this.mapping = [ {row: 0, dataId: id, rId: 0, visible: true}];
	    	this.allEntities = [ {id: id}];
	    	this.selectedRecord = {};
	        Object.assign( this.selectedRecord, {id: id});
			this.recordId = id;
	    	this.metadataDetail = {};
	    	//alert(metadata.name)
	    	let that = this;
	    	let time = new Date().getTime();
	    	let mapperLoad = new Promise(function (resolve, reject) {
		    
			
			//console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/2022-02-02/true/1");
			//console.log( JSON.stringify([node.id]));
	    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/null/true/1", [id])
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		//console.log("LOADED rights in " + dur +"ms");  
	                    that.allEntities = response.data.data;
	                    Object.assign( that.selectedRecord, that.allEntities[0]);
	                    that.metadataDetail = response.data.metadata;
	                    that.entityMap = response.data.entity2DataMap;
	                    that.showEditorDetail = true;
	                          
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    	});
	    	
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    	*/
	    },
	    closeInputs()
	    {
	        if ( this.$refs.inpColor.length )
	        {
	        	for ( let i in this.$refs.inpColor )
	        	 {
	    			this.$refs.inpColor[i].close();
	    		}
	    	}
	    	else
	    	{
	    		this.$refs.inpColor.close()
	    	}
	    },
        closeUntilPosition(position)
        {
        	//alert( position +"/" + this.path.length);
        	
        	if ( !this.path || position < this.path.length )
        	{
        		this.close();
        	}
        	this.$emit("closeUntilPosition", position)
        },
        closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
        getImgData(val) { return val?'data:image/png;base64,' + val: 'data:image/png'; },
        hideSuccess() { this.success = false; this.clearMsg(); clearInterval( timers.pop()); },
        getExtraData() {
           if ( this.data.extraData )
            {
            	for ( var i in this.data.extraData )
            	{
            		this.data[i] = this.data.extraData[i];
            	}
            }
        },
        getData(id)
        {
        	let that = this;
			
        	let dataLoad = new Promise(function (resolve, reject) 
        	{

	        	HTTP.post( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.metadata.name+"/"+id)
	                    .then( response => 
	                    {
	                    	if ( ! response.data.metadata )
	                    	{
	                    		that.data = response.data;
	                    		that.recordId = id;
	                    		that.getUser(that.data.lUserId); 
	                        }
	                        else
	                        {
	                        	that.data = response.data.data;
	                        	that.recordId = id;
	                        	that.metadata = response.data.metadata;
	                        }
							
							that.original = {... that.data};
	                        resolve( that.data) 
	                    }).catch(e => {
	      
	                        showError( that.$toast, e, "can't get "+this.metadata.name+" data ID="+id);
	                        reject();
	                    });
        	});
        	return dataLoad;
        },
        left() {
            if (this.visualIndex > 0 )
            {
                this.visualIndex--;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	this.getData(id).then(p=>{ this.proceedReading(p);});
                	
                }
                else
                {
                    this.data = {};
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getExtraData();
                	this.getUser(this.data.lUserId); 
                }
				this.original = {... this.data};
                this.recordId = this.data.id;
            }
        },
        right() {
            if (this.visualIndex+1 < this.visualMapping.length )
            {
                this.visualIndex++;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	//this.getData(id);
                	this.getData(id).then(p=>{ this.proceedReading(p);});
                }
                else
                {
               		this.data = {};
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getExtraData();
                	this.getUser(this.data.lUserId); 
                }
                this.$set( this.data, 'id', this.data.id);
				this.original = {... this.data};
                this.recordId = this.data.id;
            }
        },
        last() {
            if (this.visualIndex+1 < this.visualMapping.length )
            {
                this.visualIndex = this.visualMapping.length-1;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	this.getData(id).then(p=>{ this.proceedReading(p);});
                }
                else
                {
                    this.data = {};
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getExtraData();
                	this.getUser(this.data.lUserId); 
                }
				this.original = {... this.data};
                this.recordId = this.data.id;
            }
        },
        first() {
            if (this.visualIndex > 0 )
            {
                this.visualIndex = 0;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	this.getData(id).then(p=>{ this.proceedReading(p);});
                }
                else
                {
                    this.data = {};
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getExtraData();
                	this.getUser(this.data.lUserId); 
                }
				this.original = {... this.data};
                this.recordId = this.data.id;
            }
        },
        folderColumnsIndex(tabColumns) {
            var intArr = [];
            for ( var i=0; i < tabColumns.length; i++ )
            {
                intArr.push(i);
            }
            return intArr;
        },
        // style='display: inline-flex; width: 25%;'
        toolTip( tab, column)
        {
        	 if ( column.data.tooltip )
        	 {
        	 	return column.data.tooltip;
        	 }
        	return {};
        },
        getLabelClass( tab, column) 
        {
        	 if ( column.data.mandatory )
        	 {
        	 	return "elementLabel required";
        	 }
        	return "elementLabel";
        },
        getStyleX( tab, col) 
        { 
        	if ( col.name === 'label')
        	{
        		return "margin:0pt !important; padding: 0pt !important; height: 18pt; color: #aaa;";
        	}
            let column = col.data;
            //let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: column;
            
            let style = "";
            let folder = this.metadata.folder.find( f => f.name === tab.folder);
            if ( folder && folder.paddingBottom )
            {
            	style += "padding-bottom: "+folder.paddingBottom+";";
            }
            if ( folder && folder.paddingTop )
            {
            	style += "padding-top: "+folder.paddingTop+";";
            }
            if ( folder && folder.paddingBottom )
            {
            	style += "padding-left: "+folder.paddingLeft+";";
            }
            if ( folder && folder.paddingBottom )
            {
            	style += "padding-right: "+folder.paddingRight+";";
            }
            //console.log(tab.folder+"/"+col.data.column+"/"+style)
            if (this.visible(column)) 
            {
				
                return style+"width: 100%;";
            } 
            else 
            {
                return style+"display: none;";
            } 
        },  
        getLabelStyleX(tab, column) 
        { 
            //let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: columnName;
            let folder = this.metadata.folder.find( f => f.name === tab.folder);
            let width = "80pt;";
            if ( folder && folder.labelWidth )
	        {
            	width = folder.labelWidth;
            }
            
            let fontStyle = (column.data.tooltip)? "text-decoration: underline;": "";
            let fontSize = "font-size: 12pt;";
            if ( folder && folder.labelFontSize )
	        {
            	fontSize = "font-size: "+folder.labelFontSize+";";
            }	
            let style = fontStyle+fontSize+"width: " + width+" !important; cursor: pointer;"; //border: 1pt solid green;";
            //console.log( style);
            return style;
            /*
            if (this.visible(column)) {
                if ( this.visible(columnNext) ) 
                { 
                    return fontStyle+"width: " + (width)+unit;
                }
                else 
                {
                    return fontStyle+"width: " + (width/tab.cells)+unit;
                }
            } else {
                return fontStyle+"width: " + (width)+unit;
            } 
            */
        },  
        getFieldStyleX(tab, column) 
        { 
            //let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: columnName;
            let folder = this.metadata.folder.find( f => f.name === tab.folder);
            let lWidth = "80pt;";
            if ( folder && folder.labelWidth )
	        {
            	lWidth = folder.labelWidth;
            }
            let width = "calc(100% - "+lWidth+")"
           
            let fontStyle = (column.data && column.data.tooltip)? "text-decoration: underline;": "";
            
            return fontStyle+"width: " + (width)+" !important; cursor: pointer;"; // +";border: 1pt solid green;";
            /*
            if (this.visible(column.column)) {
                if ( this.visible(columnNext) ) 
                { 
                    return fontStyle+"width: " + (width)+unit;
                }
                else 
                {
                    return fontStyle+"width: " + (width/tab.cells)+unit;
                }
            } else {
                return fontStyle+"width: " + (width)+unit;
            } 
            */
        },  
       
        passUpdate(entity, record) {
            this.updateCount++;
        	this.$emit( "update", entity, record);
        },
        passInsert(entity, record) {
            this.updateCount++;
        	this.$emit( "insert", entity, record);
        },
        update() { 
            let that = this;
        	if ( this.data.id  )
        	{
			 for ( let f in this.data)
			 {
				if ( this.isStringArr(this.findColumn(f)) )
				{
					
					if ( !this.data[f])
					{
						this.data[f] = [];
					}
					else if (!Array.isArray(this.data[f]))
					{
						this.data[f] = this.data[f].split(',');
					}
				}
			 }
             HTTP.put( fwAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/"+this.data.id, this.data)
                    .then( response => 
                    {
                         this.metadata = response.data.metadata;
                         this.proceedReading( response.data.data, true)
                         this.success = true;
                        
                         this.$emit("update", this.metadata.name, this.data);
                         this.printMsg( 200, "Saved: "+ response.data.data.visualDisplay ); 
                    }).catch(e => {
                    	this.error("update failed", e);                    
                    });
           } else
           {
            //alert( JSON.stringify(this.data));
           	HTTP.put( fwAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/0", this.data)
                    .then( response => 
                    {
                         this.metadata = response.data.metadata;

                         this.success = true;
                         
                         this.proceedReading( response.data.data)
                         this.$emit("insert", this.metadata.name, this.data);
                         this.printMsg( 200, "Stored");
                    }).catch(e => {
                    	this.error("insert failed", e);
                        //showError( this.$toast, "insert failed", e)
                    });
           }
            
        },
        getUser(id)
        { 
            this.lastUser = {};
            if ( !id || id === 0 )
            {
            	this.$set(this.data, 'id', this.data.id);
            	this.lastUser = {login: "system"};
            }
            else
            {
            	HTTP.get( fwAPI+"/findUser/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+id)
                    .then( response => 
                    {
                    	this.$set(this.data, 'id', this.data.id);
                    	//alert( JSON.stringify(response.data))
                        this.lastUser = response.data;
                        
                    }).catch(e => {
                 
                        this.error("getUser("+id+") failed", e)
                    });
            }
        },
        selectFirstTab(tab, tabIdx)
        {
        	let firstTab = (tabIdx==0);
            //console.log("selectTab() --> " + firstTab+"/"+tabIdx+ " :"+  JSON.stringify(tab));
        	return firstTab;
        },
        tabChange(index)
        {
        	this.selectedTab = index;
        	//alert("tabChange() --> " +index);
        },
        parseMetadata()
        {
        
            this.sortDisplay( this.metadata.functions)
        	this.copyToColumn = new Array();
   	   		for ( var iCol in this.metadata.columns)
        	{
        		let col = this.metadata.columns[iCol];
        		if ( this.copyToColumn[col.column] )
    			{
    				this.copyToColumn[col.column] = new Array();
    			}
        	}
   	   		for ( iCol in this.metadata.columns)
        	{
        		let col = this.metadata.columns[iCol];
        		//console.log( col.column );
        		if ( col.copyFrom )
        		{
        			//console.log( "--> " + col.copyFrom);
        			let orig = this.findColumn(col.copyFrom);
        			//console.log( "--> " + orig.column);
        			if ( !this.copyToColumn[orig.column] )
        			{
        				this.copyToColumn[orig.column] = new Array();
        			}
        			this.copyToColumn[orig.column].push(col.column);
        			//console.log( "--> " + JSON.stringify(this.copyToColumn[orig.column]));
        		}
        	}
        	this.calcData();
        },
        get(col)
        {
        	//console.log( "**** " + col)
        	return col;
        },
        proceedReading( loadedData, skipReadDef)
        {
        	
        	this.mark= [];
			
            //this.data = this.copy(this.dataRecord);
            this.data = loadedData;
            this.recordId = this.data.id;

            for ( var col in this.metadata.columns) 
            {
            	let gCol = this.metadata.columns[col];
            	if ( gCol.enableIf )
            	{
            		let column = gCol.enableIf;
	           		let positive = true;
	           		let value = null;
	           		if ( column.startsWith("!"))
	           		{
	           			positive = false;
	           			column = column.substring(1);
	           		}
	           		if ( column.includes("="))
	           		{
	           			let arr = column.split("=")
	           			column = arr[0];
	           			value = arr[1];
	           			
	           		}
	           		gCol.editable = true;
	         	    if ( value !== null )
	         	    {
	         	    	if ( (this.data[ column ]?this.data[ column ]:0) == value)
		            	{
		            		gCol.editable = positive;
		            	}
		            	else
		            	{
		            		gCol.editable = !positive;
		            	}
	         	    }
	         	    else
	         	    {
		            	if ( this.data[ column ] )
		            	{
		            		gCol.editable = positive;
		            	}
		            	else
		            	{
		            		gCol.editable = !positive;
		            	}
	         	    }
	         	    
	         	   
	         	    //this.$forceUpdate()
	            	//alert( JSON.stringify( this.data))
	            	//alert( column+"("+value+")"+">"+positive+":"+gCol.editable+":"+this.data[ column ])
            	}
            }
            //alert(this.findColumn('listColumns').editable);
            if ( this.data.lUserId )
            {
            	this.getUser(this.data.lUserId); 
            }
            this.index = this.selectedIndex; 
            
            this.numberOfRows = 0;
            //console.log( JSON.stringify(this.mapping));
            this.visualMapping = [];
            this.visualIndex = 0;
            for ( var x in this.mapping )
            {
            	//console.log( JSON.stringify(this.mapping[x]));
            	if ( this.mapping[x].visible )
            	{
            		this.numberOfRows++;
            		
            		if ( this.data.id == this.mapping[x].dataId )
            		{
            			this.visualIndex = this.visualMapping.length;
            		}
            		this.visualMapping.push( this.mapping[x] );
            	}
            }
            this.getExtraData();
            this.calcData();
        	this.$set( this, "metadata", this.metadata);
            if ( !skipReadDef )
            {
            	this.selectedTab = 0;
            	
            }
            this.original = {... this.data};
            this.$set( this, "data", this.data);
        },
        readGivenRecord(data, meta)
        {
        	this.metadata = meta;
        	//console.log( JSON.stringify(this.dataRecord));
        	//alert( "readDataRecord");
        	if ( data.id > 0 )
        	{
        		
        		this.getData( data.id).then(p => {
        			this.proceedReading( p)
        			this.$forceUpdate();
        		});
        	}
        	else
        	{
        		
        		this.proceedReading( data )
        	}

        },
        readDataRecord()
        {
        	//console.log( JSON.stringify(this.dataRecord));
        	//alert( "readDataRecord");
        	if ( this.dataRecord.id > 0 )
        	{
        		
        		this.getData( this.dataRecord.id).then(p => {
        			this.proceedReading( p)
        			this.$forceUpdate();
        		});
        	}
        	else
        	{
        		
        		this.proceedReading( this.dataRecord )
        	}
        	//console.log( JSON.stringify( this.entityMap.solver.data))
            //this.$forceUpdate();
            //alert( "rdr " + JSON.stringify(this.data));
        },
		adjustSizes( flag)
		{
			if (this.style=='classic') 
		    { 
				if ( flag !== (this.width !== 100) )
				{ this.width = 70; this.mainHeight=85, this.height=90; } else { this.width=100; this.mainHeight=87, this.height=100; }
			}
			else
			{
				if ( flag !== (this.width !== 100) )
				{ this.width = 75; this.mainHeight=90, this.height=85; } else { this.width=100; this.mainHeight=95, this.height=100; }
			}
		}
  },
   updated() {
   
  },
  computed: {
	msgDisplayClass: function () { return this.msgClass; }
  },

  created() {
	        initAppMode();
	        let mode = isDarkMode()?"dark":"normal";
	    	import("@/themes/"+mode+"Theme.scss");
  			this.creatables = [];
  			this.parseMetadata();
			this.style = sessionStorage.editorStyle ? sessionStorage.editorStyle: "classic"; 
			this.adjustSizes();
			this.tabLayoutIDX = sessionStorage.tabLayoutIDX ? sessionStorage.tabLayoutIDX : 0;
			
  			this.detailCounter = 0;
   	    	for ( let i in this.metadata.links)
        	{
        		if ( this.metadata.links[i].createable )
        		{
        			this.creatables.push( this.metadata.links[i]);
        		}
        	}	
        	
        	if ( this.parentMeta )
        	{
	        	for ( let ii in this.parentMeta.links)
	        	{
	        		if ( this.parentMeta.links[ii].createable )
	        		{
	        			this.creatables.push( this.parentMeta.links[ii]);
	        		}
	        	}	
	        }
        	this.data = this.copy(this.dataRecord); 
			this.original = {... this.data};
        	
            if ( this.data.lUserId )
            {
            	this.getUser(this.data.lUserId); 
            }
            
            this.index = this.selectedIndex; 
            this.selectedTab = 0;
            this.numberOfRows = 0;
            //console.log( JSON.stringify(this.mapping));
            this.visualMapping = [];
            this.visualIndex = 0;
            for ( var x in this.mapping )
            {
            	//console.log( JSON.stringify(this.mapping[x]));
            	if ( this.mapping[x].visible )
            	{
            		this.numberOfRows++;
            		
            		if ( this.data.id == this.mapping[x].dataId )
            		{
            			this.visualIndex = this.visualMapping.length;
            		}
            		this.visualMapping.push( this.mapping[x] );
            	}
            }
            
            //alert( "crete " + JSON.stringify(this.data.agencyId));
  },
   watch:
   {
   	    metadata: function() {
   	        this.creatables = [];
   	        //alert("DTT> metadata");
   	        //this.data = this.copy(this.dataRecord); 
   	        //alert( JSON.stringify(this.data));
   	   		//console.log( "-------------------  metadata  ------------------------");
   	   		this.parseMetadata();
   	   		
   	    	for ( var i in this.metadata.links)
        	{
        		if ( this.metadata.links[i].createable )
        		{
        			this.creatables.push( this.metadata.links[i]);
        		}
        	}	
        	// alert(this.findColumn('listColumns').editable);
        	//alert( JSON.stringify(this.data.agencyId));
   	    },
		style: function() {
			this.adjustSizes();
			sessionStorage.editorStyle = this.style;
		},
		tabLayoutIDX: function() {
			sessionStorage.tabLayoutIDX = this.tabLayoutIDX
		},
        dataRecord: function()  { 
            //alert("DTT> dataRecord"+""+JSON.stringify(this.dataRecord));
        	this.readDataRecord();
        	//alert(this.findColumn('listColumns').editable);
        }
   }
}
</script>
<style  scoped>

.draggable {
	background-color: #ccc;
}
.ondrag {
     border-top: 0.5pt solid grey !important;
     border-left: 0.5pt solid grey !important;
	 padding-bottom: 9pt;
}
.editorElement {
     //border-top: 1px solid transparent;
     border-right: 8px solid transparent;
     //display:inline-block;
     //white-space: nowrap;
     padding-bottom: 5pt;
     padding-top: 4pt;
     padding-right: 10pt;
 }
.elementLabel {
    display: inline-flex;
    white-space: nowrap;
    float: left;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    font-size: 12pt; 
    display: inline-grid;
    white-space: nowrap;
    width: 75%;
 }
.lUpdateInfo {
    font-size: 11pt;
    color: #888;
}
.mainContent {
    height: 100% !important;
    transition: all 0.4s ease;
    position: relative;
}

.appHeadLine { 
    width: 100%; 
	height: 32pt;
    border-bottom: 1px solid #aaa; 
    text-align: right;
	position: relative;
    margin-bottom: 0pt; 
    font-weight: bold;
    padding: 5pt 0pt 0pt 0pt;
}    
.appCounter {
	display: inline-grid;
	font-size: 11pt;
	color: #888;
}    
.appLeft {
	display:  inline-flex; 
	margin-bottom: 0pt;
	text-align: left;
	width: 80%; 
} 
.appRight {
	//float: right;
	text-align: right;
	margin: 0pt;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 20%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;}
    
.modal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}
.shadow {
    border: 1px solid #ccc;
    box-shadow: 5px 5px 8px 0 #888;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.mymodal-container {
  width: 60%;
  position: relative;
  display: block;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  //background-color: transparent !important;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.5s ease;
  font-family: "Geeza Pro", "Nadeem", "Al Bayan", "DecoType Naskh", "DejaVu Serif", "STFangsong", "STHeiti", "STKaiti", "STSong", "AB AlBayan", "AB Geeza", "AB Kufi", "DecoType Naskh", "Aldhabi", "Andalus", "Sakkal Majalla", "Simplified Arabic", "Traditional Arabic", "Arabic Typesetting", "Urdu Typesetting", "Droid Naskh", "Droid Kufi", "Roboto", "Tahoma", "Times New Roman", "Arial", serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  float: top;	
  margin: 5px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-nav {
    font-size: 9pt;
    vertical-align: middle;
    v-align: middle;
}
.needed {
	background-color: #fe8 !important;
    color: #000 !important;
}
.btn-reset {
	//background-color: #efefef;
}
.btn-close {
	//background-color: #efefef;
}
.btn-editor {
 	background-color: #fda;
 	font-weight: bold;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 2pt;
    height: 22pt;
    text-align: center;
	vertical-align: middle;
    white-space: nowrap;
}
.navBN {
	//border: 1px solid grey;
	//border-radius: 8px 8px 8px 8px;
	//padding-bottom: 2pt !important;
}
.navBN:hover {
	transform: scale(1.05);
}
.msgDefault {
	font-size: 9pt;
}
.msgError {
	font-size: 9pt;
	font-weight: bold;
	color: #bb0000;
}
hr.separator {
  margin-left: 30pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding: 0pt;
  border-top: 1pt solid #ddd;
  border-radius: 5px;
}

.breadcrumbItem {
    display: inline-flex;
	position: absolute;
	bottom: 0;
    cursor: pointer;
    position: relative;
    bottom: 0pt;
	border-radius: 6px 6px 0 0;
	box-shadow: 4px 4px 5px 0 #aaa;
	border: 1px solid #ccc;
	border: 1px solid #aaa;
	margin: 0px 3px 0px 0px;
	transition: all .7s;
	margin-top: 3pt;
	margin-right: 2pt;
	padding: 4px 12px 3px 12px;
	margin-bottom: 0pt;
	background-color: #ddd;
	font-weight: normal;
	color: #000;
	
	font-size: 12px;

}

.breadcrumbItemCurrent {
    float: left;
    position: relative;
    bottom: 0pt;
	background-color: #fff;
	color: #3273dc;
	font-weight: bold;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}

.breadcrumbItem:hover {
	box-shadow: 4px 4px 5px 0 #666;
}

.settingsBlock {
    position: relative;
    display: block;
	padding-top: 2pt;
	padding-bottom: 2pt;
	height: calc(40vH);
	border-bottom: 1px solid grey;
	border-top: none;
}
</style>