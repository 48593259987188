import Vue from 'vue'
import axios from 'axios';
import {initENV} from './variables.js';

import App from './App.vue'
import router from './router'
import 'bulma/css/bulma.css';

import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import ProgressBar from 'vuejs-progress-bar'
//import VueTableDynamic from 'vue-table-dynamic'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import VueTableDynamic from '@/ext/VueTableDynamic';
import GFWEditorDefault from '@/components/GFWEditorDefault';
import GFWEditorInventory from '@/components/GFWEditorInventory';
import GFWEditorRotation from '@/components/GFWEditorRotation';
import GFWEditorRouter from '@/components/GFWEditorRouter';
import GFWEditorEditor from '@/components/GFWEditorEditor';
import GFWEOpenEditor from '@/components/GFWEOpenEditor';
import FilterTree from '@/components/filter/FilterTree';
import VModal from 'vue-js-modal'
import VueCurrencyInput from 'vue-currency-input'

import Modal from "@burhanahmeed/vue-modal-2";
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import 'vue-tree-halower/dist/halower-tree.min.css'
import {VTree, VSelectTree} from 'vue-tree-halower'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.css';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// Your app initialization logic goes here
import pageTitle from './mixins/pageTitle.js'
//import QrcodeVue from 'qrcode.vue';
import {ColorPicker, ColorPanel} from 'one-colorpicker'
import VueTree from '@ssthouse/vue-tree-chart'

axios.get(
    '/environment/env.json',
    { baseURL: window.location.origin }
  ).then( p=> {
	initENV(p.data);
	Vue.myTitle= Vue.prototype.myTitle = p.APP;
});

Vue.mixin(pageTitle)

import VuetifulBoard from 'vuetiful-board'
//import 'roboto-fontface/css/roboto/roboto-fontface.css'
//import 'material-design-icons-iconfont/dist/material-design-icons.css'

import iosAlertView from 'vue-ios-alertview';

import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'

Vue.use(mdiVue, {
  icons: mdijs
}) 

Vue.use(iosAlertView);

Vue.use(VuetifulBoard)
//import 'vue-slider-component/theme/material.css'
Vue.use(VueCurrencyInput)
Vue.use(VTree)
Vue.use(ColorPicker)
Vue.use(ColorPanel)
Vue.use(GFWEOpenEditor)
Vue.use(VSelectTree)
Vue.component('VueSlider', VueSlider)
Vue.use(VueLoading);
Vue.use(Tooltip);
Vue.use(VueIziToast);
Vue.use(Modal);
Vue.use(VModal, { componentName: 'XModal' })
Vue.use(ProgressBar)
Vue.use(require('@hscmap/vue-window'))
Vue.use(VueTableDynamic)
Vue.use(VueClipboard,VueCustomTooltip)
Vue.use(BootstrapIconsPlugin);
var Toggle = Vue.extend({
  template: '#toggle',
  props: ['toggled','label']
});


if ( !sessionStorage.myLogin )
{
    var o = {login:"", tenant:""}
    sessionStorage.myLogin = JSON.stringify(o);
}
Vue.component('vueToggle', Toggle );

Vue.component('GFWEditorDefault', GFWEditorDefault);
Vue.component('GFWEditorInventory', GFWEditorInventory);
Vue.component('GFWEditorRotation', GFWEditorRotation);
Vue.component('GFWEditorRouter', GFWEditorRouter);
Vue.component('GFWEditorEditor', GFWEditorEditor);
Vue.component('GFWEOpenEditor', GFWEOpenEditor);
Vue.component('FilterTree', FilterTree)
Vue.component('vue-tree', VueTree)

Vue.config.productionTip = false

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})





let vm = new Vue({
			router,
			store,
	        render: h => h(App),
	    });
	vm.$mount('#app')	
