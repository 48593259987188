 <template>
   <div style='display:block;  '>
	<div style="width: 100%; height: 240pt !important; overflow-x: hidden; overflow-y: auto;">
		<div v-for="(pl,pli) in playouts" :key="'PL'+pli" style="display: table-row">
			<div class="smallText" style="display: table-cell; padding-left: 5pt; padding-right: 5pt; "> {{pl.name}}</div> 
			<div class="smallText" style="display: table-cell" v-if="value && value.list && value.list.find(p=>p.familyId==pl.id)">{{ value.list.find(p=>p.familyId==pl.id).housenumber}}</div>
			<div class="smallText" style="display: table-cell" v-else><button @click="create( pl)">create</button></div>
		</div>
	</div>
  </div>
</template>

<script>
import {HTTP, fwAPI, copyWorkflowAPI,userAPI, invAPI, bngAPI, opsAPI, reportAPI, inventoryInlineAPI, placementServiceAPI, setReload, formatNumber,showError} from '@/variables.js';
import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import { formatPercent, format } from '@/Numbers.js';
import InputField from '@/components/booking/InputField';
import InputFieldS from '@/components/inputElements/InputMoneyNC';
import InputTime from '@/components/inputElements/InputTimeHHMM';
//import InputFieldS from '@/components/booking/InputFieldS';
import Switch from '@/components/Switch';
import vSelect from 'vue-select'
import ClickOutside from 'vue-click-outside';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
export default {
  name: "HOUSENUMBERS",
   components : {
	  //InputFieldS, 
	  //AIndividualChart
	  //InputTime
	  //'app-switch': Switch
  },
  props: {
	  value: Object,
	  copyId:{ type: Number, default: 0},
	  fromEditor: { type: Boolean, default: false}
  },
  data(){
    return {
    	formatPercent, getAppStyle, format,
		playouts: [],
		ready: false
     }
    },
    
    directives:{
        ClickOutside,
      },
    methods: {
		fmt( val)
    	{
       		return formatNumber( val );
    	},
		short(s)
		{
			if ( s.startsWith("That Time is "))
			{
				return s.substr(13);
			}
			if ( s.startsWith("That "))
			{
				return s.substr(5);
			}
			return s;
		},
	   create( pl)
	   {
		let that = this;
		let hnStruct = (this.value) ? this.value : {};
		
			HTTP.post( copyWorkflowAPI+"/createHousenumber/"+sessionStorage.tenantId
			   						+"/"+sessionStorage.accessPointId
			   						+"/"+sessionStorage.unitId
			   						+"/"+sessionStorage.userId
									+"/"+this.copyId
									+"/"+pl.id,
									hnStruct
			   						)
			   			
			   					 .then( response => 
			   			         {
			   						that.value = response.data;
									that.$emit( "input", that.value)
			   						
			   					 }).catch(e => {
									console.log( e)
			   			    	    showError( that.$toast, "create housenumbers", e);
			   			        });
	   },
	   
	   save()
	   {
		if ( this.editMode)
		{
			this.weekShareLimitsSave = {...this.clean(this.weekShareLimits)};
			this.$emit('input', this.clean(this.weekShareLimitsSave));
		}	
	   },
	   
	   getPlayouts()
	   {
	   		let that = this;
	   		HTTP.post( copyWorkflowAPI+"/getPlayouts/"+sessionStorage.tenantId
	   						+"/"+sessionStorage.accessPointId
	   						+"/"+sessionStorage.unitId
	   						+"/"+sessionStorage.userId
	   						)
	   			
	   					 .then( response => 
	   			         {
	   						that.playouts = response.data;
	   						
	   					 }).catch(e => {
	   			    	    showError( that.$toast, "update create Copy from Entry", e);
	   			        });
	   },
	   calculate()
	   {
			let that = this;
			
			this.$iosConfirm( "re-calculate weekly shares from existing bookings").then(x => { 
			
			
				HTTP.post( reportAPI+"/calcWeekCombinedLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId)
				    .then( response => 
				{ 
					that.value = response.data;
					
					that.init();
					
					//that.$toast.success("Week limits loaded", 'Ok', { timeout: 1000, position: "topRight" });
				}).catch(e => {
				   	that.$toast.error("Week limits", 'Error', { timeout: 1000, position: "topRight" });
				});
			});
	   },
	   loadData() {
		   let that = this;
		   HTTP.post( reportAPI+"/getWeekCombinedLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId)
	        .then( response => 
	        { 
				that.weekShareLimits = response.data;
				
	        	//that.$toast.success("Week limits loaded", 'Ok', { timeout: 1000, position: "topRight" });
	        }).catch(e => {
	        	that.$toast.error("Week limits", 'Error', { timeout: 1000, position: "topRight" });
	        });
	   },
	   equals( a, b)
	   {
		 return JSON.stringify(a) == JSON.stringify(b)  
	   },
	   containsShare( from)
	   {
			return this.shares.find( p=>p.from===from);	
	   },
	   clean( ws )
	   {
			for ( let wlsi in ws.limits)
			{
				let limit = ws.limits[ wlsi];
				//console.log( JSON.stringify( limit));	
				if ( !this.weekShareLimitList[ limit.week])
				{
					this.weekShareLimitList[ limit.week] = {};
				}
				limit.budget = this.weekShareLimitList[ limit.week][ limit.shareId];
				let share = this.shares.find( p=>p.id===limit.shareId); 
				//console.log( limit.shareId+ " --> " +JSON.stringify( share));		
				limit.from = share.from;
				limit.to = share.to;
			}
			return ws;
	   },
	   adjustWeeklies()
   	   {
   			for ( let i in this.weeklies.limits)
   			{
   				let lmt = this.weeklies.limits[i];
   				lmt.budget = this.sum( lmt.week); 	
   			}
			this.weekliesChanged = true;
   			
   	   },
	   saveWeeklyShares( ws)
	   {

			let that = this;
			for ( let wlsi in ws.limits)
			{
				let limit = ws.limits[ wlsi];

				if ( !this.weekShareLimitList[ limit.week])
				{
					this.weekShareLimitList[ limit.week] = {};
				}
				limit.budget = this.weekShareLimitList[ limit.week][ limit.shareId];
				//console.log( limit.shareId+ " --> " +JSON.stringify( this.shares));	
				let share = this.shares.find( p=>p.id==limit.shareId); 
				//console.log( limit.shareId+ " --> " +JSON.stringify( share));		
				limit.from = share.from;
				limit.to = share.to;
				//delete limit.shareId
				//console.log( JSON.stringify( limit));		
			}
			let weekShareStruct = { weekLimits: that.weeklies, weekShareLimits: ws, weekliesChanged: that.weekliesChanged};

			HTTP.put( reportAPI+"/saveWeeklyShares/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId, weekShareStruct)
	        .then( response => 
	        { 
				that.value = response.data;
	        	that.$toast.success("Weekly shares & limits saved", 'Ok', { timeout: 1000, position: "topRight" });
	        }).catch(e => {
				this.init();
				that.$toast.error(e.response, 'Ok', { timeout: 1000, position: "topRight" });
	        	showError( that.$toast, "save limits", e)
	        });
	   },
	   send(text)
	   {
			let that = this;
			let obj = { text: text};
			HTTP.put( reportAPI+"/sendWeeklyShares/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId, obj)
			        .then( response => 
			        { 
						that.value = response.data;
			        	that.$toast.success("Weekly shares & limits saved", 'Ok', { timeout: 1000, position: "topRight" });
			        }).catch(e => {
						
						that.$toast.error(e.response, 'Ok', { timeout: 1000, position: "topRight" });
			        	showError( that.$toast, "save limits", e)
			        });
	   },
	   add()
	   {
			try
			{
			    let max = this.shares && this.shares.length ? this.shares[ this.shares.length-1].to + 60 : this.timeFrom;
				let nwShare = { id: this.nextShareId++, from: max, to: max+3600-60};
				this.shares.push( nwShare);
				for ( let wi in this.weeklies.limits)
				{
					let weekly = this.weeklies.limits[wi];
					if ( !this.weekShareLimitList[ weekly.week])
					{
						this.weekShareLimitList[ weekly.week] = [];
					}
					this.weekShareLimitList[ weekly.week][ nwShare.id] = 0;
					this.weekShareLimits.limits.push({ week: weekly.week, from: nwShare.from, to: nwShare.to, budget: 0, shareId: nwShare.id});
				}
				this.save();
			} catch (e)
			{
				console.log(e)
			}
	   },


	   
  },
  updated() {
    
  },
  computed: {
	
  },
  beforeDestroy() {

  },
  created() {
  	 this.getPlayouts();
  },
   watch:
   {
	  
   }
}
</script>
<style  scoped>
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: inline-block;
}

.bold {
	font-weight: bold;
}
.smallText {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallTextSlider {
  width: 60%;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonXS {
	//width: 18%;
    padding: 0px 0px;
    outline: none;
    border-radius: 3px;
    height: 16pt;
    font-size: 10pt;
    background-color: #eef;
    border: none;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}  
.fieldLeft {
	display: block; 
	width: 76pt !important;
	font-size: 9pt;
	height: 12pt;
	overflow: hidden;
	white-space: nowrap; /* Don't forget this one */
}
.field {
	display: inline-flex; 
	width: 30pt;
	font-size: 9pt;
	height: 12pt;
}
.fieldXS {
	display: inline-flex; 
	font-size: 9pt;
	height: 12pt;
}
.fieldRow {
	display: block; 
	
	font-size: 9pt;
	height: 12pt !important;
}
.fieldTop {
	display: inline-block; 
	text-align: center;
	width: 30pt;
	font-size: 9pt;
	height: 12pt;
}
.numeric {
	text-align: right;
}
.inp {
	border: 1px solid #aaa;
}
.noInp {
	border: none;
}
textarea {
  resize: none;
}
</style>